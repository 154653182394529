import React, { useEffect, useState, useCallback } from 'react';
import io from 'socket.io-client';
import axios from 'axios';
import './App.css';  // Import the CSS file

const ENDPOINT = process.env.REACT_APP_API_ENDPOINT || "http://localhost:4000";
const socket = io(ENDPOINT, {
  withCredentials: true,
  extraHeaders: {
    "Content-Type": "application/json"
  }
});

function App() {
  const [currentBid, setCurrentBid] = useState(null);
  const [bids, setBids] = useState([]);
  const [countdown, setCountdown] = useState('');
  const [countdownColor, setCountdownColor] = useState('black');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [endTime, setEndTime] = useState(null);
  const [remainingBids, setRemainingBids] = useState(20); // Default to 20
  const [ticketData, setTicketData] = useState(null);
  const [auctionData, setAuctionData] = useState(null); // New state for auction data
  const [bidIncrement, setBidIncrement] = useState(null);
  const [timeIncrement, setTimeIncrement] = useState(null); // New state for incremental time
  const [maxBidsPerUser, setMaxBidsPerUser] = useState(null); // New state for max bids per user
  const [serverTime, setServerTime] = useState(new Date().toLocaleString()); // New state for server time
  const searchParams = new URLSearchParams(window.location.search);
  const ticketNumber = searchParams.get('param1');
  const [userNickname, setUserNickname] = useState(''); // State for user's nickname

  const calculateCountdown = useCallback(() => {
    if (endTime === null) return "Loading...";

    const now = new Date().getTime();
    const distance = endTime - now;

    if (distance < 0) {
      return "EXPIRED!";
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (distance <= 30000) {
      setCountdownColor('red');
    } else if (distance <= 60000) {
      setCountdownColor('orange');
    } else {
      setCountdownColor('black');
    }

    return `${days} : ${hours} : ${minutes} : ${seconds}`;
  }, [endTime]);

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  const formatCurrency = (value) => {
    return `€${parseFloat(value).toFixed(2)}`;
  };

  const updateRemainingBids = useCallback((bids, userId, maxBids) => {
    const userBids = bids.filter(bid => bid.userId === userId).length;
    return maxBids - userBids;
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ENDPOINT}/data`, {
          headers: {
            "Content-Type": "application/json"
          }
        });
        if (response && response.data) {
          setCurrentBid(prevBid => prevBid === null ? response.data.currentBid : prevBid);
          setBids(response.data.bids);
          setEndTime(response.data.endTime);
          setTimeIncrement(response.data.timeIncrement); // Set the incremental time value
          setMaxBidsPerUser(response.data.maxBidsPerUser); // Set the max bids per user value
          setRemainingBids(response.data.maxBidsPerUser); // Set remaining bids to maxBidsPerUser initially
        }
      } catch (error) {
        console.error('There was an error fetching the data!', error);
      }
    };

    fetchData();

    socket.on('initialData', (data) => {
      setCurrentBid(prevBid => prevBid === null ? data.currentBid : prevBid);
      setBids(data.bids);
      setEndTime(data.endTime);
      setTimeIncrement(data.timeIncrement); // Set the incremental time value
      setMaxBidsPerUser(data.maxBidsPerUser); // Set the max bids per user value
      setRemainingBids(data.maxBidsPerUser); // Set remaining bids to maxBidsPerUser initially
    });

    socket.on('newBid', (data) => {
      setCurrentBid(data.currentBid);
      setBids(data.bids);
      setEndTime(data.endTime);
      setMessage(`New bid placed! ${timeIncrement / 1000} seconds added to the countdown.`);
      if (data.bids && userNickname) {
        setRemainingBids(updateRemainingBids(data.bids, userNickname, maxBidsPerUser));
      }

      setTimeout(() => {
        setMessage('');
      }, 5000);
    });

    const timer = setInterval(() => {
      const newCountdown = calculateCountdown();
      setCountdown(newCountdown);
    }, 1000);

    const serverTimeInterval = setInterval(() => {
      setServerTime(new Date().toLocaleString());
    }, 1000);

    if (ticketNumber) {
      axios.get(`${ENDPOINT}/ticket`, {
        params: { numTicket: ticketNumber },
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          if (response && response.data) {
            setTicketData(response.data.ticket);
            setAuctionData(response.data.auction); // Set auction data
            setUserNickname(response.data.ticket.nickName); // Set the user's nickname
            setCurrentBid(prevBid => prevBid === null ? response.data.auction.baseAsta : prevBid); // Initialize currentBid with baseAsta if null
            setBidIncrement(response.data.auction.valOffer); // Set bid increment value
            setTimeIncrement(response.data.auction.addTime); // Set incremental time value
            setMaxBidsPerUser(response.data.auction.maxNumbersOffert); // Set max bids per user value
            setRemainingBids(response.data.auction.maxNumbersOffert); // Set remainingBids to maxNumbersOffert initially
            if (response.data.bids) {
              setRemainingBids(updateRemainingBids(response.data.bids, response.data.ticket.nickName, response.data.auction.maxNumbersOffert));
            }
          }
        })
        .catch(error => {
          console.error('There was an error fetching the ticket data!', error);
        });
    }

    return () => {
      clearInterval(timer);
      clearInterval(serverTimeInterval);
      socket.off('initialData');
      socket.off('newBid');
    };
  }, [calculateCountdown, ticketNumber, userNickname, timeIncrement, maxBidsPerUser, updateRemainingBids]);

  const handleBid = () => {
    const now = new Date().getTime();
    if (endTime !== null && now >= endTime) {
      setErrorMessage('The auction has ended.');
    } else if (remainingBids > 0 && endTime !== null && now < endTime) {
      axios.post(`${ENDPOINT}/placeBid`, { userId: userNickname, bidIncrement, codeAsta: auctionData.codiceAsta }) // Use current bidIncrement
        .then(response => {
          if (response && response.data) {
            setCurrentBid(response.data.currentBid);
            setBids(response.data.bids);
            setEndTime(response.data.endTime);
            setErrorMessage('');
            setRemainingBids(updateRemainingBids(response.data.bids, userNickname, maxBidsPerUser));
          }
        })
        .catch(error => {
          if (error.response && error.response.data) {
            setErrorMessage(error.response.data.error);
          } else {
            setErrorMessage('There was an error placing the bid.');
          }
        });
    } else {
      setErrorMessage('You have reached the maximum number of bids.');
    }

    setTimeout(() => {
      setErrorMessage('');
    }, 5000);
  };

  const handleExit = () => {
    setCurrentBid(currentBid);
    setBids([]);
    setCountdown('');
    setCountdownColor('black');
    setMessage('');
    setErrorMessage('');
    setEndTime(null);
    setRemainingBids(maxBidsPerUser);
    setTicketData(null);
    setAuctionData(null); // Reset auction data
    setUserNickname('');
    // Additional logic to reset session, if required
  };

  useEffect(() => {
    if (bids.length > 0 && userNickname && maxBidsPerUser) {
      setRemainingBids(updateRemainingBids(bids, userNickname, maxBidsPerUser));
    }
  }, [bids, userNickname, maxBidsPerUser, updateRemainingBids]);

  return (
    <div className="App">
      <div className="menu-bar">
        <div className="logo-container">
          <img src="Ticket2bid-sito-400x67.png" alt="Logo" />
          <span>__Cockpit</span>
        </div>
        <div>
          <span>{userNickname}</span>  {/* Display the user's nickname */}
          <button onClick={handleExit}>
            Exit
          </button>
        </div>
      </div>
      <div className="card-container">
        <div className="card">
          <h1>Current Bid: €{currentBid !== null ? currentBid.toFixed(2) : 'Loading...'}</h1>
          <div>
            <span>Bid Increment: €{bidIncrement ? bidIncrement.toFixed(2) : 'Loading...'}</span>
            <button onClick={handleBid}>
              Place a Bid
            </button>
          </div>
          <div>
            <h4 style={{ color: remainingBids <= 5 ? 'red' : 'black' }}>You have {remainingBids} bids remaining of {maxBidsPerUser}</h4>
          </div>
          {message && <div className="message message-success">{message}</div>}
          {errorMessage && <div className="message message-error">{errorMessage}</div>}
          <h3>Countdown to the end:</h3>
          <h1 className="countdown" style={{ color: countdownColor }}>{countdown}</h1>
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Time</th>
                  <th>Nickname</th>
                  <th>Bid Amount (€)</th>
                </tr>
              </thead>
              <tbody>
                {bids.slice().reverse().slice(0, 5).map((bid, index) => (
                  <tr key={index}>
                    <td>{bids.length - index}</td>
                    <td>{bid.time}</td>
                    <td>{bid.userId}</td>
                    <td>{bid.value.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="server-time">Server Time GMT+2 : {serverTime}</div>
        </div>
        {ticketData && (
          <div className="card">
            <h2>Ticket Information</h2>
            <div className="table-responsive">
              <table>
                <tbody>
                  <tr>
                    <td><strong>Ticket Number:</strong></td>
                    <td>{ticketData.numTicket}</td>
                  </tr>
                  <tr>
                    <td><strong>Asta Code:</strong></td>
                    <td>{ticketData.astacodeTicket}</td>
                  </tr>
                  <tr>
                    <td><strong>Customer Email:</strong></td>
                    <td>{ticketData.mailcustTicket}</td>
                  </tr>
                  <tr>
                    <td><strong>Nickname:</strong></td>
                    <td>{ticketData.nickName}</td>
                  </tr>
                  <tr>
                    <td><strong>Base Asta:</strong></td>
                    <td>{auctionData ? formatCurrency(auctionData.baseAsta) : 'Loading...'}</td>
                  </tr>
                  <tr>
                    <td><strong>Start Date:</strong></td>
                    <td>{auctionData ? formatDateTime(auctionData.dataInizio) : 'Loading...'}</td>
                  </tr>
                  <tr>
                    <td><strong>Publication Date:</strong></td>
                    <td>{auctionData ? formatDateTime(auctionData.dataPubblicazione) : 'Loading...'}</td>
                  </tr>
                  <tr>
                    <td><strong>Max Number of Offers:</strong></td>
                    <td>{auctionData ? auctionData.maxNumbersOffert : 'Loading...'}</td>
                  </tr>
                  <tr>
                    <td><strong>Add Time:</strong></td>
                    <td>{auctionData ? auctionData.addTime : 'Loading...'}</td>
                  </tr>
                  <tr>
                    <td><strong>Offer Value:</strong></td>
                    <td>{auctionData ? formatCurrency(auctionData.valOffer) : 'Loading...'}</td>
                  </tr>
                  <tr>
                    <td><strong>Auction Starting Bid:</strong></td>
                    <td>{auctionData ? formatCurrency(auctionData.baseAsta) : 'Loading...'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        {auctionData && (
          <div className="card">
            <h2>Auction Information</h2>
            <div className="table-responsive">
              <table>
                <tbody>
                  <tr>
                    <td><strong>Title:</strong></td>
                    <td>{auctionData.titoloAsta}</td>
                  </tr>
                  <tr>
                    <td><strong>Description:</strong></td>
                    <td>{auctionData.DescrAsta}</td>
                  </tr>
                  <tr>
                    <td><strong>Market Value:</strong></td>
                    <td>€{auctionData.valMercato.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td><strong>Photo:</strong></td>
                    <td><img src={auctionData.foto} alt={auctionData.titoloAsta} /></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <a href={auctionData.urlAction} target="_blank" rel="noopener noreferrer">
              <button className="read-more-button">
                Read More
              </button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
